.news {
    section#news-hero {
        overflow: hidden;
        padding-top: rem(80);
        border-bottom: 16px solid $secondary-purple;
        .wrapper {
            position: relative;
            // padding: 0 rem(50);
        }

        .row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .text-container {
            padding: 0 0 rem(60) 0;
            .left-side {
                margin-right: rem(30);
                flex: 1 0 50%;

                @include mobile {
                    margin-right: unset;
                    margin-left: rem(30);
                }
            }
            .right-side {
                font-family: $akkurat;
                margin-left: rem(30);
                flex: 0 1 50%;
            }
        }
    }

    .featured-event {
        display: flex;
        background: linear-gradient(180deg, #fff 0%, #efeff1 100%);
        padding: rem(60) rem(160);

        @include tablet-lg {
            padding: rem(60);
        }

        @include mobile {
            padding: rem(20);
        }

        .flex-row {
            @include tablet-lg {
                flex-direction: column;
            }
        }

        .text-container {
            flex: 1 0 50%;
            margin-right: rem(40);

            @include tablet-lg {
                flex: 0 1 100%;
                margin-right: unset;
                margin-bottom: rem(40);
            }

            .h3 {
                color: $secondary-purple;
            }

            .date,
            .description {
                font-family: $akkurat;
            }
        }
        .right-side {
            flex: 0 1 50%;
        }

        .image-container {
            min-height: rem(300);
            aspect-ratio: 3.5 / 2;

            @include tablet {
                min-height: unset;
            }

            img {
                border-radius: rem(16);
                max-width: unset;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .latest-news {
        display: flex;

        .wrapper {
            display: flex;
            padding-left: 0px;
            padding-right: 0px;

            @include tablet {
                flex-direction: column;
            }
        }

        @include tablet {
            flex-direction: column;
        }

        #reactNews {
            flex: 0 1 66%;
        }

        .upcoming-events {
            flex: 1 1 33%;
            background-color: #efeff1;
            padding: rem(48) 0 0 rem(32);

            @include tablet {
                padding: rem(20);
            }

            .h3 {
                color: $secondary-purple;
            }

            .events-container {
                padding: rem(25) rem(48) rem(25) 0;
                min-height: rem(130);

                &.flex-row {
                    @include mobile {
                        align-items: flex-start;
                    }
                }

                .event {
                    flex: 1 1 auto;
                    border-bottom: 1px solid #ccc;

                    @include mobile {
                        margin-top: rem(25);
                    }
                    &-name {
                        @include font(20, auto);
                        font-weight: 625;
                        color: $secondary-purple;
                        margin: rem(16) 0;

                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    .date-container {
                        font-family: $akkurat;
                        align-items: center;
                        margin-bottom: rem(10);
                        .date {
                        }
                        .time {
                            margin-top: 0 !important;
                        }

                        &.flex-row {
                            @include mobile {
                                flex-direction: row;
                            }
                        }
                    }

                    .divider {
                        position: relative;
                        margin: 0 rem(60);

                        &::after {
                            content: "";
                            display: block;
                            height: rem(157);
                            border: 2px solid $secondary-purple;
                        }

                        &.small {
                            margin: 0 rem(10);

                            &::after {
                                height: rem(19);
                                border: 1px solid $light-gray;
                            }
                        }

                        &.no-mbl {
                            @include tablet {
                                display: none;
                            }
                        }
                    }
                }
                .button {
                    flex: 1 1 auto;

                    &.no-mbl {
                        @include tablet {
                            display: none;
                        }
                    }
                }
            }

            .button {
                flex: 1 1 auto;

                &.mbl-only {
                    display: none;

                    @include tablet {
                        display: flex;
                        align-self: flex-end;
                        margin: 0 0 rem(30) 0;
                    }

                    @include mobile {
                        align-self: flex-start;
                    }
                }
            }

            .twitter-cta {
                padding: rem(40) rem(40) rem(40) 0;
                margin-top: rem(30);
                align-items: flex-start;
                border-top: 1px solid #ccc;

                .text-container {
                    display: flex;
                    margin-top: rem(20);
                }

                .twitter-icon {
                    width: 25px;
                    height: 25px;
                    margin-top: rem(5);
                    margin-right: rem(15);
                }

                a.h4 {
                    color: $secondary-purple;
                    font-weight: 625;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
