.news-detail {
    section#banner-section {
        overflow: hidden;
        padding-top: rem(80);
        .wrapper {
            position: relative;
            // padding: 0 rem(50);
        }

        .button {
            min-width: unset;
        }

        .callout-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .featured-image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: rem(40) 0;

            .banner-img-container {
                overflow: hidden;
                border-radius: rem(16);
                background-color: $white;
                border: 1px solid #dfdfe2;
                border-radius: rem(16);

                img {
                    padding: rem(40);
                }
            }
        }

        .row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .text-container {
            padding: 0 0 rem(60) 0;
            .left-side {
                margin-right: rem(30);
                flex: 1 0 50%;
            }
            .right-side {
                font-family: $akkurat;
                margin-left: rem(30);
                flex: 0 1 50%;
            }
        }

        &::after {
            position: absolute;
            content: "";
            display: block;
            height: 100%;
            width: 100vw;
            top: 0;
            left: 0;
            background: linear-gradient(0deg, #fff 0%, #efeff1 100%);
            z-index: -1;
        }
    }

    .wysiwyg-section {
        .text-container {
            margin: rem(20) rem(60);
            font-family: $akkurat;
        }
    }
}
