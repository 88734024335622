.engineering-nitrogen-fixation {
    background: linear-gradient(180deg, #efeff1 0%, #fff 100%);
    border-radius: rem(16);

    .h3 {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            margin-right: rem(16);
        }
    }
    &__container {
        display: flex;
        padding: rem(40) rem(50);
        gap: rem(16);

        @include tablet {
            gap: rem(24);
        }

        @include mobile {
            flex-direction: column;
            padding: rem(20) 0;
        }

        .enf-card {
            display: flex;
            flex-direction: column;

            flex: 0 1 20%;

            .image-container {
                height: rem(160);
                display: flex;
                flex-direction: column;

                @include mobile {
                    background-color: $white;
                    flex-direction: row-reverse;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include mobile {
                        object-fit: contain;
                    }
                }
                .deco {
                    &-1,
                    &-2,
                    &-3,
                    &-4,
                    &-5 {
                        content: "";
                        position: relative;
                        display: block;
                        width: 100%;
                        height: rem(48);
                        background-color: $white;

                        @include mobile {
                            width: rem(48);
                            height: 100%;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            opacity: 0;
                            border-radius: 50%;

                            background-color: $white;
                            border: 2px solid $primary-purple;
                            z-index: 2;

                            filter: drop-shadow(0 0 0.75rem #a293c1);

                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            height: 0;
                            bottom: 0;
                            left: 0;
                            width: 100%;

                            @include mobile {
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .text-container {
                position: relative;
                margin-top: rem(60);

                @include tablet {
                    .h4 {
                        @include font(18, auto);
                    }

                    ul {
                        padding-left: rem(10);
                    }
                }

                .h4 {
                    color: $secondary-purple;
                    font-weight: 625;
                }

                .description,
                ul,
                li {
                    font-family: $akkurat;
                    font-weight: 400;
                }
            }
        }
    }

    &.animate-on-scroll-active {
        .deco {
            &-1 {
                &::before {
                    animation: growSize1 1s ease-in forwards;
                }

                &::after {
                    background-color: #e2dded;
                    animation: growHeight1 0.75s ease-in forwards;
                }
                @keyframes growHeight1 {
                    to {
                        height: 25%;
                    }
                }
                @keyframes growSize1 {
                    to {
                        opacity: 1;
                        width: rem(16);
                        height: rem(16);
                    }
                }
            }

            &-2 {
                &::before {
                    animation: growSize2 1s ease-in forwards;
                }

                &::after {
                    background-color: #ccc4de;
                    animation: growHeight2 0.75s ease-in forwards;
                }
                @keyframes growHeight2 {
                    to {
                        height: 35%;
                    }
                }
                @keyframes growSize2 {
                    to {
                        opacity: 1;
                        width: rem(24);
                        height: rem(24);
                    }
                }
            }

            &-3 {
                &::before {
                    animation: growSize3 1s ease-in forwards;
                }

                &::after {
                    background-color: #a293c1;
                    animation: growHeight3 0.75s ease-in forwards;
                }
                @keyframes growHeight3 {
                    to {
                        height: 50%;
                    }
                }
                @keyframes growSize3 {
                    to {
                        opacity: 1;
                        width: rem(36);
                        height: rem(36);
                    }
                }
            }

            &-4 {
                &::before {
                    animation: growSize4 1s ease-in forwards;
                }

                &::after {
                    background-color: #836daa;
                    animation: growHeight4 0.75s ease-in forwards;
                }
                @keyframes growHeight4 {
                    to {
                        height: 65%;
                    }
                }
                @keyframes growSize4 {
                    to {
                        opacity: 1;
                        width: rem(40);
                        height: rem(40);
                    }
                }
            }

            &-5 {
                &::before {
                    animation: growSize5 1s ease-in forwards;
                }

                &::after {
                    background-color: #664c95;
                    animation: growHeight5 0.75s ease-in forwards;
                }
                @keyframes growHeight5 {
                    to {
                        height: 85%;
                    }
                }
                @keyframes growSize5 {
                    to {
                        opacity: 1;
                        width: rem(48);
                        height: rem(48);
                    }
                }
            }
        }
    }

    .deco {
        &-1 {
            @include mobile {
                &::before {
                    opacity: 1 !important;
                    width: rem(16) !important;
                    height: rem(16) !important;
                }

                &::after {
                    background-color: #e2dded !important;
                    width: rem(16) !important;
                }
            }
        }

        &-2 {
            @include mobile {
                &::before {
                    opacity: 1 !important;
                    width: rem(24) !important;
                    height: rem(24) !important;
                }

                &::after {
                    background-color: #ccc4de !important;
                    width: rem(24) !important;
                }
            }
        }

        &-3 {
            @include mobile {
                &::before {
                    opacity: 1 !important;
                    width: rem(36) !important;
                    height: rem(36) !important;
                }

                &::after {
                    background-color: #a293c1 !important;
                    width: rem(36) !important;
                }
            }
        }

        &-4 {
            @include mobile {
                &::before {
                    opacity: 1 !important;
                    width: rem(40) !important;
                    height: rem(40) !important;
                }

                &::after {
                    background-color: #836daa !important;
                    width: rem(40) !important;
                }
            }
        }

        &-5 {
            @include mobile {
                &::before {
                    opacity: 1 !important;
                    width: rem(48) !important;
                    height: rem(48) !important;
                }

                &::after {
                    background-color: #664c95 !important;
                    width: rem(48) !important;
                }
            }
        }
    }
}
