// LatestNews.scss

section#latest-news {
    border-top: 16px solid $secondary-purple;
    border-bottom: 16px solid $light-gray;
}
.latest-news-component {
    padding: rem(48) rem(17) rem(80) rem(48);

    @include mobile {
        padding: rem(20);
    }

    .latest-news-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        margin-bottom: rem(16);

        .flex-row {
            align-items: baseline;
            margin-bottom: rem(40);

            h2 {
                margin-right: rem(22);
            }

            .post-count {
                color: $secondary-purple;
                font-weight: 625;
                text-transform: uppercase;
                @include font(12, auto);
            }
        }

        .category-buttons {
            display: flex;
            align-items: center;
            background-color: #efeff1;
            padding: rem(16);

            p.super-header {
                @include font(12, auto);
                letter-spacing: rem(2.88);
                font-weight: 625;
            }

            a.super-header {
                @include font(14, auto);
                background-color: rgba(78, 42, 132, 0.1);

                border-radius: rem(4);

                padding: rem(8);
                margin: rem(8);

                align-self: flex-start;
                font-weight: 550;
                letter-spacing: rem(3.36);

                &.active {
                    background-color: $secondary-purple;
                    color: $white;
                }
            }
        }

        select.mobile-dropdown-selector {
            @include font(12, auto);
            letter-spacing: rem(2.88);
            font-weight: 625;
            width: 50%;
            background-color: rgba(78, 42, 132, 0.1);
            border: none;
            font-family: $campton;
            text-transform: uppercase;
            padding: 0 rem(10);
            color: $secondary-purple;
        }
    }

    .latest-news-list {
        .news-article {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $light-gray;
            padding: rem(16) rem(0) rem(40) rem(0);
            margin-bottom: 20px;

            &:last-child {
                border-bottom: 8px solid $secondary-purple;
            }

            @include mobile {
                flex-direction: column;
            }

            img {
                width: rem(200);
                height: rem(200);
                object-fit: cover;
                border-radius: rem(16);
                margin-right: rem(24);

                @include mobile {
                    width: 50%;
                    height: auto;
                    margin-bottom: rem(20);
                    align-self: flex-start;
                }
            }

            p.h3 {
                @include font(24, auto);
                color: $secondary-purple;
                font-weight: 625;
            }

            p.description {
                font-family: $akkurat;
                @include font(16, auto, 24);
            }

            .article-content {
                display: flex;
                flex-direction: column;
                flex: 1;

                .category {
                    @include font(14, auto);
                    background-color: rgba(78, 42, 132, 0.1);
                    padding: rem(8);
                    align-self: flex-start;
                    font-weight: 625;
                    letter-spacing: rem(3.36);
                }

                .button.link {
                    min-width: unset;
                    width: rem(140);
                    justify-content: flex-start;

                    .arrow {
                        &::after {
                            margin-left: rem(10);
                        }
                    }
                }
            }
        }
    }

    .pagination {
        display: flex;
        margin-top: 20px;

        a {
            padding: rem(8) rem(16);
            margin-right: 5px;
            text-decoration: underline;
            cursor: pointer;

            &.pagination-arrow {
                display: flex;
                align-items: center;
            }

            &.active {
                background-color: $secondary-purple;
                color: #fff;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }
}
