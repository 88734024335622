//
// Name:            Forms
//
// Description:     Contains all form styles
//
// ========================================================================
// Component: Form
// ========================================================================
.form-wrapper {
	margin: 20px auto;
	padding: 40px 0;
	text-align: left;
	h1 {
		margin: 0 0 20px 0;
	}
}

optgroup {
	font: inherit;
	font-weight: bold;
}

input {
	// Removes inner padding and border in Firefox 4+.
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

input {
	// Remove inner padding and search cancel button in Chrome, Safari and Opera on OS X.
	&[type="search"] {
		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration {}
	} // Remove button on `.number` input
	&[type="number"] {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			appearance: none;
			margin: 0;
		}
	}

	&:-ms-input-placeholder {
		opacity: 1;
		color: $black;
	}

	&::-moz-placeholder {
		opacity: 1;
		color: $black;
	}

	&::-webkit-input-placeholder {
		opacity: 1;
		color: $black;
	}

	&:disabled {
		&:-ms-input-placeholder {}
		&::-moz-placeholder {}
		&::-webkit-input-placeholder {}
	}
}

// Define consistent border, margin, and padding.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

// Removes `.box-shadow` for invalid controls in Firefox.
:invalid {
	box-shadow: none;
}

::-ms-clear {
	display: none;
}

// Style
// ========================================================================
// Remove margin from the last-child
form> {
	:last-child {
		margin-bottom: 0;
	}
}

// Controls
// Expand width to container of [class*="col-"]
select,
textarea,
input:not([type]),
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	width: 100%;
	[class*="col-"]>& {
		width: 100%;
		display: block;
	}
}

input {
	&[type="checkbox"],
	&[type="radio"] {
		width: auto;
		display: inline-block;
	}
}

// Legend
legend {
	width: 100%;
	border: 0;
	padding: 0;
	&:after {
		content: "";
		display: block;
		width: 100%;
	}
}

// Reset height
// Must be after size modifiers
// ========================================================================
textarea,
select[multiple],
select[size] {
	height: auto;
}

// Sub-objects: `.label`
// ========================================================================
label {
	@include font(10, 2, auto);
	display: inline-block;
	position: relative;

	margin: 0;

	text-transform: uppercase;
	font-weight: 700;

	vertical-align: middle;
	cursor: pointer;
}

.form {
	&-footer {
		*+& {}
	}
	&-error {
		label.error {
			@include font(12, auto, 0);
		}
	}
	&-success {
		label.success {
			@include font(12, auto, 0);
		}
	}
}

.gform_fields {
	&,
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	li {
		position: relative;
		margin-bottom: 20px;
	}
	>li {
		display: flex;
		flex-direction: column;
		+li {}
		>label {}
	}
}

input[type="text"],
input[type="email"],
input[type="password"] {
	@include font($form-field-font-size, auto, $form-field-height);
	height: $form-field-height;

	border: $form-field-border;
	border-radius: $form-field-border-radius;
	padding: $form-field-padding;

	color: $form-field-color;
	background: $white;

	box-shadow: inset 0 2px 6px rgba($shadow, 0.15);

	font-family: 'Brandon Grotesque', sans-serif;

	&.error {
		border: 1px solid $red;
	}
	&:focus {
		background-color: $white;
		box-shadow: 0 0 4px $secondary-purple;
	}
	&.valid {
		background-color: $white;
	}
}

select {
	background: $white;
	border: $form-field-border;
	height: $form-field-height;
}

.selectric {
	background: $white;
	border: $form-field-border;
	border-radius: $form-field-border-radius;
	.label {
		font-size: $form-field-font-size;
		color: $form-field-color;
	}
	.button:after {
		// @include transition(.6s);
	}
}

.selectric-open .button:after {
	// @include rotate(180deg);
}

.selectric-scroll li.highlighted {
	background: #f2f2f2;
}

.selectric-scroll li {
	margin: 0;
}

.selectric-scroll li:hover {
	background: #f2f2f2;
}

textarea {
	background: $white;
	border: $form-field-border;
	height: 140px;
	padding: 10px;
	color: $form-field-color;
	font-size: $form-field-font-size;
	border-radius: $form-field-border-radius;
}

input[type="submit"] {
	@include font(18, 0, 40);

	height: 50px;
	width: 100%;
	padding: 5px;

	border-radius: $form-field-border-radius;
	border: 2px solid $secondary-purple;

	background: $secondary-purple;
	color: $white;
	text-transform: uppercase;
	font-weight: 900;
	cursor: pointer;

	&:hover {
		background: $white;
	}
}

.ginput_container_fileupload {
	.button {
		background: $secondary-purple;
		color: $white;
		border: 2px solid $secondary-purple;
		line-height: 32px;
	}
	#extensions_message {
		padding-left: 20px;
		font-size: 11px;
		font-style: italic;
	}
	.inputfile {
		position: absolute;
		top: 0;
		left: 0;

		opacity: 0;
		padding: 0;
		margin: 0;
		z-index: 1;
		overflow: hidden;
		cursor: pointer;
		@include size(100%);

		&:hover {
			+label {}
		}
		+label {
			color: inherit;
			max-width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: inline-block;
			overflow: hidden;
			text-transform: none;
			display: inline-flex;
			align-items: center;
			transition: all linear 200ms;
			svg {
				width: 1em;
				height: 1em;
				vertical-align: middle;
			}
		}
	}
}

.gfield_checkbox {
	display: inline-block;
	position: relative;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		width: auto;
	}
	label {
		line-height: 20px;
		padding-left: 30px;
		padding-right: 30px;
	}
	label:before {
		position: absolute;
		content: '';
		transition: .3s cubic-bezier(.68, -.55, .265, 1.55);
		top: 0;
		left: 5px;
		width: 20px;
		height: 20px;
		background: $white;
		color: $secondary-purple;
		@include border-radius(2px);
		background-clip: padding-box;
		border: 1px solid #ccc;
		text-align: center;
	}
	input:checked+label:before {
		content: '✔';
	}
	label.disabled {
		opacity: 0.75;
	}
	input {
		position: absolute;
		display: block;
		@include size(100%);
		top: 0;
		left: 0;
		z-index: 1;
		cursor: pointer;
		opacity: 0;
	}
}

.gfield_radio {
	display: inline-block;
	position: relative;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		width: auto;
	}
	label {
		padding-left: 30px;
		padding-right: 30px;
		line-height: 20px;
		&:before {
			top: 4px;
			right: 4px;
			top: 0;
			left: 4px;
			width: 20px;
			height: 20px;
			background: $white;
			border-radius: 100%;
			position: absolute;
			content: '';
			border: 1px solid #ccc;
			transition: .3s cubic-bezier(.68, -.55, .265, 1.55);
		}
	}
	input:checked+label:before {
		background: $secondary-purple;
	}
	&.disabled {
		opacity: 0.75;
	}
	input {
		position: absolute;
		display: block;
		@include size(100%);
		top: 0;
		left: 0;
		z-index: 1;
		cursor: pointer;
		opacity: 0;
	}
}

.gform_footer {
	margin-top: 20px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.validation_error {
	padding: 10px 0;
	color: $red;
	font-size: 14px;
	text-align: center;
}

.validation_message {
	color: $red;
	font-size: 14px;
	text-align: right;
}
