//
// Name:            Footer
//
// Description:     Contains all footer styles
//
// ========================================================================
// Component: Footer
// =========================================================================

.footer,
.sub-footer {
	@include font(14, auto, 16);

	position: relative;
	width: 100%;
	padding: rem(45) 0 rem(20) 0;
	background-color: $secondary-purple;
	color: $text-light;
	overflow: hidden;

	@include mobile {
		padding: rem(15) 0 rem(15) 0;
	}

	.wrapper {
		@include mobile {
			padding: rem(32);
		}
	}

	.row {
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		@include mobile {
			flex-flow: row wrap;
			justify-content: flex-start;
			text-align: left;
		}
	}

	.nav {
		@media only screen and (max-width: $breakpoint-lg) and (min-width: 1px) {
			flex: 0 0 100%;
			max-width: 100%;

			padding: 20px 0;
		}

		ul {
			@include list-reset;
			display: flex;

			@media only screen and (max-width: $breakpoint-lg) and (min-width: 1px) {
				flex-flow: column nowrap;
			}

			li {
				color: $text-light;
				margin: 0 rem(12);

				@include mobile {
					margin: rem(12) 0;
				}

				a {
					@include font(16, auto);
					color: $text-light;

					&:hover,
					&:focus {
						text-decoration: underline;
					}

					// &:hover,
					// &:focus {
					// 	color: $secondary-purple;
					// }
				}
			}
		}
	}

	.side-nav {
		padding: rem(35) 0 0 0;

		@include mobile {
			padding: rem(10) 0;
		}
		ul {
			@include list-reset;

			li {
				padding: rem(5) 0;
				a {
					line-height: rem(22);
					color: $text-light;

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.footer-contact {
		&.row {
			@include mobile {
				flex-direction: column;
			}
		}
		.locations {
			@include mobile {
				padding-top: rem(30);
			}
			.location {
				@include font(16, auto);
				font-family: $akkurat;
				padding-left: rem(24);
				padding-right: rem(24);
				font-weight: 400;

				&:first-of-type {
					padding-left: 0;
				}

				@include mobile {
					padding-left: 0;
					padding-right: 0;
					padding: rem(12) 0;
				}

				p {
					margin-top: rem(5);
				}

				.city {
					font-weight: 700;
				}
			}
		}

		.socials {
			@include mobile {
				align-items: flex-start;
			}
			.super-header {
				font-size: rem(12);
				color: $text-light;
				padding-right: rem(32);
			}
		}
	}

	.logo {
		display: block;
		width: rem(223);

		margin-right: 15px;
	}

	.socials {
		align-items: center;
		padding-top: rem(40);
		.social-icon-container {
			display: flex;

			@include mobile {
				padding-top: rem(10);
			}
		}
		.social-logo {
			padding: rem(5) rem(5) rem(5) rem(5);

			&:hover {

			}

			&:first-child {
				padding: rem(5) rem(5) rem(5) 0;
			}
		}
	}
}

.sub-footer {
	padding: rem(20) 0;
	background-color: $primary-purple;
	display: flex;
	align-items: center;

	.wrapper {
		width: 100vw;

		.row {
			align-items: center;
			@include mobile {
				justify-content: center;
				text-align: center;
			}

			.copyright {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				p {
					margin-top: 0;
				}
			}

			.h3 {
				color: $white;
				max-width: rem(500);
				text-align: center;
				margin-top: 0;
			}

			.copyright,
			.h3,
			.logo {
				padding: rem(12) 0;
			}
		}
	}
}
