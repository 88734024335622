//
// Name:            Fonts
//
// Description:     Contains all fonts
//
// ========================================================================
// Component: Fonts
// ========================================================================

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-LightItalic.woff2") format("woff2"), url("../fonts/Campton-LightItalic.woff") format("woff");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-ExtraLightItalic.woff2") format("woff2"), url("../fonts/Campton-ExtraLightItalic.woff") format("woff");
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-SemiBold.woff2") format("woff2"), url("../fonts/Campton-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-Thin.woff2") format("woff2"), url("../fonts/Campton-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-MediumItalic.woff2") format("woff2"), url("../fonts/Campton-MediumItalic.woff") format("woff");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-Light.woff2") format("woff2"), url("../fonts/Campton-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/Campton-ExtraBoldItalic.woff") format("woff");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-SemiBoldItalic.woff2") format("woff2"), url("../fonts/Campton-SemiBoldItalic.woff") format("woff");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-ExtraLight.woff2") format("woff2"), url("../fonts/Campton-ExtraLight.woff") format("woff");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-BlackItalic.woff2") format("woff2"), url("../fonts/Campton-BlackItalic.woff") format("woff");
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-Black.woff2") format("woff2"), url("../fonts/Campton-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton Book";
	src: url("../fonts/Campton-BookItalic.woff2") format("woff2"), url("../fonts/Campton-BookItalic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton Book";
	src: url("../fonts/Campton-Bold.woff2") format("woff2"), url("../fonts/Campton-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton Book";
	src: url("../fonts/Campton-Book.woff2") format("woff2"), url("../fonts/Campton-Book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton Book";
	src: url("../fonts/Campton-BoldItalic.woff2") format("woff2"), url("../fonts/Campton-BoldItalic.woff") format("woff");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-ThinItalic.woff2") format("woff2"), url("../fonts/Campton-ThinItalic.woff") format("woff");
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Campton";
	src: url("../fonts/Campton-ExtraBold.woff2") format("woff2"), url("../fonts/Campton-ExtraBold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Campton Regular";
	src: url("../fonts/Campton-Medium.woff2") format("woff2"), url("../fonts/Campton-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/AkkuratPro-Bold.woff2') format('woff2'),
        url('../fonts/AkkuratPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/AkkuratPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/AkkuratPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/AkkuratPro-Light.woff2') format('woff2'),
        url('../fonts/AkkuratPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akkurat Pro Regular';
    src: url('../fonts/AkkuratPro-Regular.woff2') format('woff2'),
        url('../fonts/AkkuratPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/AkkuratPro-Italic.woff2') format('woff2'),
        url('../fonts/AkkuratPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('../fonts/AkkuratPro-LightItalic.woff2') format('woff2'),
        url('../fonts/AkkuratPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
