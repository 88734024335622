.large-stat {
    display: inline-flex;
    flex: 0 1 auto;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: auto;

    max-width: rem(551);
    max-height: rem(121);

    padding: rem(16) rem(60);
    background: #4e2a84;
    border-radius: 16px;
    overflow: hidden;
    gap: 24px;

    margin: rem(10) 0;

    @include tablet-lg {
        padding: rem(16) rem(20);

    }

    @include tablet {
        flex-direction: column;
        max-height: none;
        align-items: flex-start;
    }

    &__num {
        width: auto;
        color: white;
        font-size: 96px;
        font-family: $campton;
        font-weight: 625;
        line-height: 100px;
        flex: 1 1 50%;
    }

    &__text {
        flex: 1 1 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: inline-flex;

        .large-stat-header {
            width: 240px;
            color: white;
            font-size: 12px;
            font-family: $campton;
            font-weight: 625;
            text-transform: uppercase;
            letter-spacing: 2.88px;
            word-wrap: break-word;
            margin-top: 0;
        }

        .large-stat-desc {
            align-self: stretch;
            color: white;
            font-size: 14px;
            font-family: $akkurat;
            font-style: italic;
            font-weight: 400;
            line-height: 16.8px;
            word-wrap: break-word;
            margin-top: 0;
        }
    }
}
