.search {
    section#search-hero {
        overflow: hidden;
        padding-top: rem(80);
        background-color: #efeff1;

        .search-wrapper {
            margin: rem(45) rem(275);

            @include tablet {
                margin: rem(45) rem(150);
            }

            @include mobile {
                margin: rem(50);
            }
        }

        h2 {
            margin-bottom: rem(35);
        }

        label {
            @include font(16, auto);
            text-transform: capitalize;
            font-family: $akkurat;
            font-weight: 400;
            color: $secondary-purple;
            line-height: 24px;
        }

        #search-form {
            display: grid;
            align-items: center;

            input.search-site {
                height: rem(45);
                font-family: $akkurat;
                @include font(24, auto);
                font-weight: 400;
            }
            input.search-icon {
                position: absolute;
                display: flex;
                justify-self: flex-end;
                width: rem(24);
                height: rem(24);
                margin: rem(12);
            }
        }
    }

    .search-results {
        margin: 0 rem(275);

        @include tablet-lg {
            margin: 0 rem(150);
        }

        @include mobile {
            margin: rem(50);
        }
        .wrapper {
            margin-top: rem(35);
            margin-bottom: rem(100);
        }
        .results-container {
            margin-top: rem(25);
            margin-bottom: rem(48);

            border-bottom: 8px solid $secondary-purple;

            .news-article {
                border-bottom: 0.5px solid #dedee2;
                padding: rem(40) 0;

                .article-content {
                    display: flex;
                    flex-direction: column;
                    .category {
                        background-color: rgba(78, 42, 132, 0.1);

                        border-radius: rem(4);
                        padding: rem(8);

                        align-self: flex-start;
                        font-weight: 550;
                        letter-spacing: rem(3.36);
                    }
                    .h4 {
                        color: $secondary-purple;
                        font-weight: 625;
                    }

                    .button {
                        min-width: unset;
                    }
                }
            }
        }

        .pagination {
            display: flex;
            margin-top: 20px;

            a {
                padding: rem(8) rem(16);
                margin-right: 5px;
                text-decoration: underline;
                cursor: pointer;

                &.pagination-arrow {
                    display: flex;
                    align-items: center;
                }

                &.active {
                    background-color: $secondary-purple;
                    color: #fff;
                }

                &.disabled {
                    opacity: 0.5;
                }
            }
        }
    }
}
