.flex {
    section#flex-hero {
        overflow: hidden;
        padding-top: rem(80);

        .wrapper {
            position: relative;
            // padding: 0 rem(50);
        }

        .row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .text-container {
            padding: 0 0 rem(60) 0;
            .left-side {
                margin-right: rem(30);
                flex: 1 0 50%;
            }
            .right-side {
                font-family: $akkurat;
                margin-left: rem(30);
                flex: 0 1 50%;
            }
        }

        &::after {
            position: absolute;
            content: "";
            display: block;
            height: 75%;
            width: 100vw;
            top: 0;
            left: 0;
            background-color: $light-gray;
            z-index: -1;
        }
    }

    .banner-img-container {
        position: relative;
        width: 100%;
        max-width: rem(1344);
        max-height: rem(500);
        margin: 0 auto;
        overflow: hidden;
        border-radius: rem(24);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: none;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(31, 0, 53, 0.52) 0%, rgba(31, 0, 53, 0.52) 100%);
        }
    }

    &-gen-content {
        margin: rem(86) rem(320) rem(50) rem(320);

        @include tablet-lg {
            margin: rem(86) rem(112) rem(50) rem(112);
        }

        @include mobile {
            margin: rem(20);
        }

        h2,
        h3 {
            color: $secondary-purple;
        }

        p.description,
        ul {
            font-family: $akkurat;
        }

        .block-quote {
            margin: 28px 0;
            padding: 0 40px;
            border-left: 4px solid $secondary-purple;
        }
    }

    .image-content-section {
        .flex-row {
            padding: rem(75) 0;
            border-top: 0.5px solid #dedee2;
        }
        .text-container {
            .h3 {
                color: $secondary-purple;
                font-weight: 550;
            }
        }

        .large-stat {
            margin-top: rem(35);
            border-radius: rem(124);

            @include tablet {
                flex-direction: row;
            }

            @include mobile {
                border-radius: rem(16);
                flex-direction: column;
            }
        }
        .img-container {
            width: rem(500);
            height: auto;
            aspect-ratio: 1 / 1;

            @include tablet {
                width: rem(300);
            }

            @include mobile {
                width: auto;
                margin-bottom: rem(15);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: rem(16);
            }
        }

        &.left {
            .text-container {
                margin-right: rem(150);

                @include tablet {
                    margin-right: unset;
                }
            }
        }

        &.right {
            .text-container {
                margin-left: rem(150);

                @include tablet {
                    margin-left: unset;
                }
            }
        }
    }

    .card-grid-section {
        border-top: 0.5px solid #dedee2;
        padding: rem(75) rem(48);

        @include mobile {
            padding: rem(20);
        }

        h2 {
            margin-bottom: rem(30);
        }

        .basic-card-grid {
            display: flex;
            gap: 15px;

            @include tablet {
                flex-wrap: wrap;
            }

            .basic-card {
                display: flex;
                flex-direction: column;
                background: linear-gradient(180deg, #efeff1 0%, #fff 100%);
                border: 1px solid #dfdfe2;
                border-radius: rem(24);

                flex: 1 1 33%;

                @include tablet {
                    flex: 0 1 49%;
                }

                @include mobile {
                    flex: 0 1 100%;
                }

                .image-container {
                    width: 100%;
                    height: 45%;
                    // flex: 0 1 33%;

                    @include mobile {
                        height: 35%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-top-right-radius: rem(24);
                        border-top-left-radius: rem(24);
                    }
                }

                .text-container {
                    padding: rem(24);
                    display: flex;
                    flex-direction: column;
                    height: 55%;
                    justify-content: space-between;

                    .h4 {
                        color: $secondary-purple;
                        font-weight: 700;
                    }

                    p.description {
                        font-family: $akkurat;
                    }

                    .button {
                        min-width: unset;
                        justify-self: flex-end;
                    }
                }
            }
        }
    }

    .headshot-section {
        border-top: 0.5px solid #dedee2;
        padding: rem(75) rem(48);

        @include mobile {
            padding: rem(20);
        }

        .person-profile {
            display: flex;
            background: linear-gradient(180deg, #efeff1 0%, #fff 100%);
            padding: rem(48) rem(48);
            margin: rem(32) 0;
            border: 1px solid #dfdfe2;
            border-radius: rem(16);

            @include mobile {
                flex-direction: column;
                padding: rem(20);
            }
            .left-side,
            .right-side {
                display: flex;
                flex-direction: column;

                @include mobile {
                    flex: 0 1 100%;
                    margin-right: unset;
                }
            }

            .left-side {
                flex: 1 0 33%;
                margin-right: rem(36);

                @include mobile {
                     margin-right: unset;
                }
            }
            .right-side {
                flex: 0 1 66%;
            }
            &.type-1 {
            }
        }

        .people-grid {
            display: flex;
            flex-direction: row;
            gap: 15px;
            justify-content: space-between;

            @include tablet {
                flex-wrap: wrap;
            }

            .people-card {
                background: linear-gradient(180deg, #efeff1 0%, #fff 100%);
                border-radius: 16px;
                border: 1px solid #dfdfe2;

                padding: rem(16) rem(24);
                flex: 0 1 25%;

                @include tablet {
                    flex: 0 1 49%;
                }

                @include mobile {
                    flex: 0 1 100%;
                }

                .image-container {
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        max-width: unset;
                        border-radius: rem(16);
                    }
                }

                .text-container {
                    margin-top: rem(24);
                    .h4 {
                        color: $secondary-purple;
                    }

                    p.title {
                        margin-top: 0;
                        margin-bottom: rem(20);
                        color: $text-primary;
                        opacity: 0.8;
                    }
                    .contact {
                        color: $text-primary;
                        font-family: $akkurat;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .headshot-grid-section {
        padding: rem(75) 0;

        // rest of styles in accordion-grid.scss
    }

    .enf-section {
        padding: rem(150) 0;

        .engineering-nitrogen-fixation {
            padding: rem(40) rem(50);

            @include mobile {
                padding: rem(20) rem(25);
            }
            .h2 {
                margin-bottom: rem(35);
            }
        }
    }

    .complex-visual-headshots {
        .h2 {
            margin-bottom: rem(35);
        }
    }

    .accordion {
        .wrapper {
            padding: rem(40) rem(48);
            border-top: 0.5px solid #dedee2;

            @include mobile {
                padding: rem(20);
            }
        }
        .h2 {
            margin-bottom: rem(35);
        }
    }

    .col-left {
        margin-right: rem(100);

        @include mobile {
            margin-right: unset;
        }
    }
}
