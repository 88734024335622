.small-stat {
    width: 322px;
    height: 160px;
    padding: 24px;
    background: linear-gradient(180deg, #f0f0f1 0%, white 100%);
    border-radius: 16px;
    overflow: hidden;
    border-top: 8px #4e2a84 solid;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2px;

    display: inline-flex;
    position: relative;
    top: 27%;
    left: 10%;

    @include mobile {
        flex-direction: column;
        max-height: none;
        top: 0;
        left: 0;
        width: 100%;
    }

    &__num {
        align-self: stretch;
        color: #4e2a84;
        font-size: 48px;
        font-family: $campton;
        font-weight: 625;
        word-wrap: break-word;
    }

    &__text {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2px;
        display: flex;

        .small-stat-header {
            width: 274px;
            color: #4e2a84;
            font-size: 12px;
            font-family: $campton;
            font-weight: 625;
            text-transform: uppercase;
            letter-spacing: 2.88px;
            word-wrap: break-word;
            margin-top: 0;
        }

        .small-stat-desc {
            width: 274px;
            color: #1a1a1a;
            font-size: 14px;
            font-family: $akkurat;
            font-style: italic;
            font-weight: 400;
            line-height: 16.8px;
            word-wrap: break-word;
            margin-top: 0;
        }
    }
}
