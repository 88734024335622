.full-footer {
    position: relative;
    z-index: 8;
    .super-footer-container {
        position: relative;
        top: 94px;
        z-index: 3;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        background-color: $white;
        margin: 0 rem(160);

        @include tablet {
            margin: 0 rem(10);
        }

        @include mobile {
            top: 0px;
        }

        .text-container {
            padding: rem(46) rem(41);
            text-align: left;
            .h2 {
                color: $secondary-purple;
            }

            @include tablet {
                .h2 {
                    @include font(48, auto);
                }
            }

            @include mobile {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: rem(16);
                text-align: center;

                .h2 {
                    @include font(24, auto);
                }

                .super-header {
                    @include font(14, auto);
                    margin-bottom: rem(12);
                    letter-spacing: 3.36px;
                    width: rem(250);
                }
            }
        }
    }
    .footer-grid-container {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 100%;
        padding: rem(25) 0;
        width: 100%;
        height: rem(752);
        max-width: rem(1400);
        margin: 0 auto;

        @include mobile {
            grid-template-rows: 33% 33% 33%;
            grid-template-columns: 100%;
            gap: rem(16);
            height: rem(1000);
            padding: rem(50) 0;
        }

        .photo {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                max-width: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
            }

            &#footer-img1 {
                border-top-left-radius: rem(16);
                border-bottom-left-radius: rem(16);
                @include mobile {
                    border-radius: rem(16);
                }
            }

            &#footer-img2 {
                @include mobile {
                    border-radius: rem(16);
                }
            }
            &#footer-img3 {
                border-top-right-radius: rem(16);
                border-bottom-right-radius: rem(16);
                @include mobile {
                    border-radius: rem(16);
                }
            }

            &:hover {
                .overlay {
                    background: linear-gradient(0deg, rgba(48, 24, 85, 0.9) 0%, rgba(48, 24, 85, 0.9) 100%);
                    opacity: 1;
                }

                .text-container {
                    .button {
                        color: $secondary-purple;
                        background-color: $white;

                        border: 1px $white solid;

                        text-decoration: none;

                        .arrow {
                            &::after {
                                background-image: url("../images/arrow-right-purple.svg");
                            }
                        }
                    }
                }
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            z-index: 2;
        }

        .text-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            text-align: left;

            width: 100%;

            bottom: 0;
            margin-bottom: rem(30);
            margin-left: rem(32);

            z-index: 2;

            @include mobile {
                margin-left: rem(16);
            }

            .flex-row {
                width: 90%;
                justify-content: space-between;
            }

            .h2,
            .title {
                color: $text-light;
            }

            .button {
                min-width: unset;
            }

            .title {
                @include font(24, auto);
                font-weight: 550;
                margin-top: rem(10);
                width: 90%;

                @include tablet {
                    @include font(18, auto);
                    font-weight: 400;
                }

                @include mobile {
                    @include font(18, auto);
                    font-weight: 400;
                }
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100vw;
            height: rem(100);
            background-color: $secondary-purple;
            z-index: -1;

            @include mobile {
                display: none;
            }
        }
    }
}
