section.section {
  padding: rem(100) 0;

  h2 {
    margin-bottom: 45px;
    color: $text-secondary;
  }

  .row {
    > div {
      opacity: 0;
      transform: translate(0, 20px);
      transition: 0.3s;
    }
  }

  &.loaded {
    .row {
      > div {
        opacity: 1;
        transform: translate(0);
      }
    }
  }

  @media only screen and (max-width: $breakpoint-lg) {
    padding: 75px 0;
  }

  @include mobile {
    padding: rem(50) 0;
  }
}
