.partnership {
    width: 100%;
    height: auto;
    padding: rem(16) rem(24);
    max-width: 551px;
    // max-height: 122px;
    background: linear-gradient(90deg, #efeff1 0%, white 100%);
    border-radius: 16px;
    overflow: hidden;
    border-left: 4px #4e2a84 solid;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    margin: 1rem 0;

    @include mobile {
        flex-direction: column;
        max-height: none;
        align-items: flex-start;
    }

    &__img {
        position: relative;
        flex: 0 0 auto;
        width: rem(90);
        height: rem(90);

        img {
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
            border: 1px white solid;
        }
    }
    &__text {
        flex: 1 1 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: inline-flex;

        .partnership-header {
            width: 222px;
            color: #4e2a84;
            font-size: 12px;
            font-family: $campton;
            font-weight: 625;
            text-transform: uppercase;
            letter-spacing: 2.88px;
            word-wrap: break-word;
            margin-top: 0;
        }

        .partnership-desc {
            align-self: stretch;
            color: #1a1a1a;
            font-size: 14px;
            font-family: $akkurat;
            font-style: italic;
            font-weight: 400;
            line-height: 16.8px;
            word-wrap: break-word;
            margin-top: 0;
        }
    }
}
