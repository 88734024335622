//
// Name:            Header
//
// Description:     Contains all header styles
//
// ========================================================================
// Component: Header
// ========================================================================
header.header {
	position: sticky;
	top: rem(44);
	left: 0;

	width: 100%;
	height: 95px;

	background: $white;
	color: $white;

	border-bottom: 1px solid $light-gray;

	z-index: 9;

	&.sticky {
		position: sticky;
	}

	@include mobile {
		height: 70px;
	}

	.wrapper {
		@include tablet-lg {
			padding-left: rem(20);
			padding-right: rem(20);
		}
	}

	.header-top {
		position: absolute;
		top: rem(-44) !important;
		width: 100vw;
		background: $secondary-purple;

		&::before {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 60%;
			height: 44px;
			background: $light-gray;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: 70%;
			height: 44px;
			background-image: url("/images/global/Rectangle.svg");
			background-repeat: no-repeat;
		}

		@include mobile {
			&::before,
			&::after {
				display: none;
			}
		}
	}
	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;

		height: 95px;

		margin-top: 0;
		z-index: 1;

		@include mobile {
			height: 70px;
		}

		&.util-nav {
			position: relative;
			height: rem(44);

			.logo {
				display: inline-block;
				width: 140px;
				height: 17px;
				margin: 0;
				overflow: hidden;

				a {
					display: inline-block;
					width: 140px;
					height: 17px;
					text-indent: -9999px;
					background-image: url("/images/global/Northwestern.svg");
					background-size: 100%;
					background-repeat: no-repeat;
				}
			}
			.util-topnav {
				@include font(15, auto);
				font-weight: 475;

				#search-form {
					display: flex;
					margin-left: rem(23);
					align-items: center;
					border-bottom: 1px solid $secondary-purple;
					.search-site {
						background-color: transparent;
						border: none;

						&::placeholder {
							font-family: $campton;
							@include font(15, auto);
							font-weight: 475;
							color: $secondary-purple;
						}
					}
					.search-icon {
						width: rem(16);
						height: rem(16);
					}
				}
			}
		}
	}

	.logo,
	.header-shrink-logo {
		display: inline-block;
		width: 250px;
		height: 60px;

		margin: 0;
		overflow: hidden;

		a {
			display: inline-block;
			width: 100%;
			height: 100%;
			text-indent: -9999px;
			background-image: url("/images/global/CSB-logo.svg");
			background-size: 100%;
			background-repeat: no-repeat;
		}

		@include tablet-lg {
			width: 200px;
			height: 50px;
		}
	}

	.mbl-logo {
		position: absolute;
		opacity: 0;
		width: 60px;
		height: 60px;

		margin: 0;
		overflow: hidden;
		transition: all 0.6s ease-in-out;

		a {
			display: inline-block;
			width: 100%;
			height: 100%;
			text-indent: -9999px;
			background-image: url("/images/global/CSB-logo-mobile.svg");
			background-size: 100%;
			background-repeat: no-repeat;
		}

		&.show {
			opacity: 1;
		}

		&.not-homepage {
			position: relative;
			opacity: 1;
		}
	}

	.logo {
		display: block;

		@include mobile {
			display: none;
		}

		&.homepage {
			display: none;
		}
	}

	.header-shrink-logo {
		transform: translate(25%, 260%) scale(1.5);
		transition: all 0.4s ease-in-out;
		display: none;
		opacity: 0;

		@include tablet {
			transform: translate(38%, 311%) scale(1.5);
		}

		@include mobile {
			transform: translate(49%, 223%) scale(2);
		}

		&.homepage {
			display: block;
			opacity: 1;
		}

		&.shrink {
			transform: translate(0%, 0%) scale(1);

			@include mobile {
				opacity: 0;
			}
		}
	}

	.hamburger {
		@include button-reset;

		display: none;
		padding: 10px;
		cursor: pointer;

		.menu {
			color: $secondary-purple;
			font-family: $campton;
			font-size: 17px;
			font-weight: 550;
			padding-right: rem(16);
		}

		&:hover {
			.hamburger-box {
				&:before,
				&:after {
					background-color: $secondary-purple;
				}
			}

			.hamburger-inner {
				background-color: $secondary-purple;
			}
		}

		&-box {
			display: block;
			position: relative;
			width: 35px;
			height: 25px;

			&:before,
			&:after {
				content: "";
				position: absolute;
				left: 0;

				height: 4px;

				background-color: $secondary-purple;

				transform: translateY(0) rotate(0deg);
				transform-origin: center;
				transition:
					transform 0.3s ease,
					top 0.3s ease;
			}

			&:before {
				width: 100%;
				top: 0;
			}

			&:after {
				width: 80%;
				top: calc(100% - 4px);
			}
		}

		&-inner {
			@include vertical-center;
			left: 0;

			width: 60%;
			height: 4px;

			background-color: $secondary-purple;
		}

		&.is-active {
			.hamburger-box {
				&:before {
					top: 50%;
					transform: translateY(-50%) rotate(45deg);
				}

				&:after {
					width: 100%;
					top: 50%;
					transform: translateY(-50%) rotate(-45deg);
				}
			}

			.hamburger-inner {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	header.header {
		.row {
			margin: 0;
		}
		.hamburger {
			display: flex;
		}
		.row > [class*="col-"]:first-child {
			margin-top: 0;
		}
	}
}

@media only screen and (max-width: 568px) and (min-width: 1px) {
	header.header {
		.logo {
			a {
			}
		}
		.hamburger {
			padding-right: 10px;
			&:after {
				display: block;
			}
		}
	}
}
