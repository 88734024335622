#hero {
	background: linear-gradient(180deg, #fff 0%, #efeff1 100%);
	.flex-row {
		align-items: center;
	}
	.logo-container {
		position: relative;
		min-width: rem(375);
		height: rem(73);

		img {
			max-width: none;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.divider {
		position: relative;
		margin: 0 rem(60);

		&::after {
			content: "";
			display: block;
			height: rem(157);
			border: 2px solid $secondary-purple;
		}

		&.large {
			@include tablet {
				left: rem(-50);
				margin: 0;
			}

			@include mobile {
				display: none;
			}
		}

		&.medium {
			margin: 0 rem(20.5);

			&::after {
				height: rem(131);
				border: 1px solid $light-gray;
			}

			@include mobile {
				display: none;
			}
		}

		&.small {
			margin: 0 rem(10);

			&::after {
				height: rem(19);
				border: 1px solid $light-gray;
			}
		}

		&.no-mbl {
			@include tablet {
				display: none;
			}
		}
	}

	.hero-grid-container {
		display: grid;
		grid-template-columns: 66% 33%;
		grid-template-rows: 50% 50%;
		padding: rem(25) 0;
		width: 100%;
		max-height: rem(496);
		max-width: rem(1344);
		margin: 0 auto;

		@include tablet {
			max-height: rem(733);
			max-width: rem(902);
			grid-template-columns: 50% 50%;
			grid-template-rows: 66% 33%;
		}

		.photo {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
			}
			&:hover {
				.overlay {
					background: linear-gradient(0deg, rgba(48, 24, 85, 0.9) 0%, rgba(48, 24, 85, 0.9) 100%);
					opacity: 1;
				}

				.arrow-icon {
					background-image: url("/images/global/arrow-right-purple.svg");
					background-color: white;
				}

				.text-container {
					.title {
						text-decoration: underline;
					}
				}
			}
		}

		.photo-large {
			grid-column: 1 / span 1;
			grid-row: 1 / span 2;
			border-top-left-radius: rem(16);
			border-bottom-left-radius: rem(16);

			img {
				max-width: none;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@include tablet {
				grid-column: 1 / span 2;
				grid-row: 1 / span 1;
				border-top-right-radius: rem(16);
				border-bottom-left-radius: 0;
			}
		}

		.photo-small {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			img {
				max-width: none;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&#hero-img2 {
				grid-column: 2 / span 1;
				grid-row: 1 / span 1;
				border-top-right-radius: rem(16);

				@include tablet {
					grid-column: 1 / span 1;
					grid-row: 2 / span 1;
					border-top-right-radius: 0;
					border-bottom-left-radius: rem(16);
				}
			}

			&#hero-img3 {
				grid-column: 2 / span 1;
				grid-row: 2 / span 1;
				border-bottom-right-radius: rem(16);

				@include tablet {
					grid-column: 2 / span 1;
					grid-row: 2 / span 1;
				}
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
			z-index: 2;
		}

		.text-container {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			text-align: left;

			width: 100%;

			bottom: 0;
			margin-bottom: rem(30);
			z-index: 2;

			.flex-row {
				width: 90%;
				justify-content: space-between;
			}

			.super-header,
			.title {
				margin-left: rem(32);
				color: $text-light;
			}

			.super-header {
				@include font(14, auto);
			}

			.title {
				@include font(20, auto);
				font-weight: 625;
				margin-top: rem(10);
			}
		}

		.arrow-icon {
			position: absolute;
			right: rem(20);
			width: rem(24);
			height: rem(24);
			z-index: 3;
			background-image: url("/images/global/arrow-right-white.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-color: rgba(255, 255, 255, 0);
			padding: rem(6);
			border-radius: rem(4);
			transition: all 0.5s ease-in-out;
		}
	}

	.upcoming-events {
		display: flex;
		flex-direction: column;
		padding: 0 rem(25);

		@include tablet {
			padding: 0 rem(20);
		}

		@include mobile {
			padding-left: rem(10);
		}

		p.super-header {
			@include font(14, auto);
			letter-spacing: 3.36px;

			@include mobile {
				margin-top: rem(25);

			}
		}
		.events-container {
			padding: rem(25) 0;
			min-height: rem(130);

			&.flex-row {
				@include mobile {
					align-items: flex-start;
				}
			}

			.event {
				max-width: rem(300);
				flex: 1 1 auto;

				@include mobile {
					margin-top: rem(25);
					max-width: unset;

					&:first-child {
						margin-top: 0;
					}
				}
				&-name {
					@include font(20, auto);
					font-weight: 625;
					color: $secondary-purple;
					margin-bottom: rem(30);

					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;

					&:hover {
						text-decoration: underline;
					}
				}
				.date-container {
					font-family: $akkurat;
					.date {
					}
					.time {
						margin-top: 0 !important;
					}

					&.flex-row {
						@include mobile {
							flex-direction: row;
						}
					}
				}
			}
			.button {
				flex: 1 1 auto;

				&.no-mbl {
					@include tablet {
						display: none;
					}
				}
			}
		}

		.button {
			flex: 1 1 auto;

			&.mbl-only {
				display: none;

				@include tablet {
					display: flex;
					align-self: flex-end;
					margin: 0 0 rem(30) 0;
				}

				@include mobile {
					align-self: flex-start;
				}
			}
		}
	}

	.mobile-hero-container {
		display: flex;
		flex-direction: column;

		.photo {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			&::after {
				content: "";
				display: block;
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
			}
			// &:hover {
			// 	.overlay {
			// 		background: linear-gradient(0deg, rgba(48, 24, 85, 0.9) 0%, rgba(48, 24, 85, 0.9) 100%);
			// 		opacity: 1;
			// 	}

			// 	.arrow-icon {
			// 		background-image: url("/images/global/arrow-right-purple.svg");
			// 		background-color: white;
			// 	}

			// 	.text-container {
			// 		.title {
			// 			text-decoration: underline;
			// 		}
			// 	}
			// }
		}

		.photo-large {
			width: 100%;
			border-radius: rem(16);

			img {
				max-width: none;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.mbl-hero-image {
			display: flex;
			flex-direction: row;

			background-color: $light-gray;
			border-radius: rem(16);

			padding: rem(16);
			margin: rem(5) 0;

			&:first-child {
				flex-direction: column;
				background-color: unset;
				padding: unset;

				.mbl-hero-image-container {
					position: relative;
					width: 100%;
					height: rem(170);
					flex: 1 1 auto;

					&::after {
						content: "";
						position: absolute;
						display: block;
						background-color: $light-gray;
						width: 100%;
						height: rem(100);
						bottom: 0;
						left: 0;
						z-index: -1;
					}
				}

				.text-container {
					background-color: $light-gray;
					border-bottom-left-radius: rem(16);
					border-bottom-right-radius: rem(16);

					padding: rem(16);
					margin-left: 0;
				}
			}

			&-container {
				width: rem(100);
				height: rem(100);
				flex: 0 0 auto;

				img {
					max-width: none;
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: rem(16);
				}
			}

			.text-container {
				display: flex;
				flex-direction: column;
				flex: 0 1 auto;
				margin-left: rem(8);

				.title {
					margin-top: rem(5);
					@include font(16, auto, auto);
				}

				.super-header {
					@include font(14, auto);
				}
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
			z-index: 2;
		}
	}
}
