.form-container {
    margin-bottom: rem(50);
    border-radius: rem(16);
    background-color: $secondary-purple;

    .form-inside {
        display: flex;
        padding: rem(64) rem(113);

        @include tablet {
            flex-direction: column;
            padding: 20px; /* Adjust padding for mobile view */
        }
    }

    .h2,
    .h3, .super-header {
        color: $white;
    }
}

.left-column {
    flex: 1;
    padding: 20px;
    color: $white;

    @include mobile {
        padding: 10px; /* Adjust padding for mobile view */
    }
}

.right-column {
    flex: 1;
    padding: 20px;
    color: $white;

    @include mobile {
        padding: 10px; /* Adjust padding for mobile view */
    }
}

.form {
    display: flex;
    flex-wrap: wrap;
}

.form-group {
    flex: 1 1 100%;
    padding: 10px;

    &#phone {
        flex: 0 1 50%;

        @include mobile { 
            flex: 0 1 100%;
        }
    }
}

.form-group label {
    font-family: $akkurat;
    text-transform: capitalize;
    @include font(16, auto);
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    color: $white;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid $white;
    border-radius: 4px;
    background-color: $white;
    color: $secondary-purple;

    /* Adding light gray color for placeholder text */
    &::placeholder {
        font-family: $akkurat;
        color: #15171a;
        opacity: 0.3;
    }
}

.form-group select {
    appearance: menulist;
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;

    @include mobile { 
        grid-template-columns: 1fr;
    }
}

.form-group textarea {
    min-height: 150px;
}

.form-group button {
    justify-content: center;
    max-width: none;
    width: 100%;
    border: none;
    cursor: pointer;
}
