//
// Name:            Buttons
//
// Description:     Contains all button styles
//
// ========================================================================
// Component
// ========================================================================
.button {
	@include font(12, 2, 36);
	font-weight: 625;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;

	align-self: flex-start;
	margin-top: rem(30);

	height: auto;
	width: auto;
	min-width: rem(261);

	padding: rem(8) rem(24);

	border: 1px $secondary-purple solid;
	border-radius: 4px;

	color: $text-light;
	background-color: $secondary-purple;

	text-transform: uppercase;
	text-align: center;

	transition: all 0.5s ease-in-out;

	@include mobile {
		min-width: unset;
	}

	&.link {
		color: $secondary-purple;
		background-color: unset;
		border: unset;
		border-radius: unset;
		padding: unset;

		&:hover {
			background-color: unset;
			border: unset;
			text-decoration: underline;
		}

		.arrow {
			&::after {
				content: "";
				position: relative;
				display: block;
				background-image: url("/images/global/arrow-right-purple.svg");
				margin-left: rem(16);
				height: rem(24);
				width: rem(24);
				transition: all 0.5s ease-in-out;
			}

			&.down {
				&::after {
					transform: rotate(90deg);
				}
			}
		}
	}

	&.large {
		height: 60px;
		line-height: 60px;
	}
	.arrow {
		&::after {
			content: "";
			position: relative;
			display: block;
			background-image: url("/images/global/arrow-right-white.svg");
			margin-left: rem(16);
			height: rem(24);
			width: rem(24);
			transition: all 0.5s ease-in-out;
		}

		&.down {
			&::after {
				transform: rotate(90deg);
			}
		}
		&.left {
			&::after {
				background-image: url("/images/global/arrow-left-purple.svg");
				margin-left: unset;
				margin-right: rem(16);
				transform: rotate(180deg);
			}
		}
	}
	span.plus {
		&::after {
			content: "";
			position: relative;
			display: block;
			background-image: url("/images/research/plus-circle.svg");
			margin-left: rem(16);
			height: rem(24);
			width: rem(24);
			transition: all 0.5s ease-in-out;
		}
	}

	span.minus {
		&::after {
			content: "";
			position: relative;
			display: block;
			background-image: url("/images/research/minus-circle.svg");
			margin-left: rem(16);
			height: rem(24);
			width: rem(24);
			transition: all 0.5s ease-in-out;
		}

		&.light {
			&::after {
				background-image: url("/images/research/minus-circle-white.svg");
			}
		}
	}
	&.light {
		color: $secondary-purple;
		background-color: $light-purple;

		border: 1px $light-purple solid;

		.arrow {
			&::after {
				background-image: url("/images/global/arrow-right-purple.svg");
			}
		}
		&:hover {
			color: $light-purple;
			background: $secondary-purple;
			border: 1px $secondary-purple solid;

			.arrow {
				&::after {
					background-image: url("/images/global/arrow-right-white.svg");
				}
			}
		}
	}

	&.white {
		color: $secondary-purple;
		background-color: $white;

		border: 1px $white solid;

		.arrow {
			&::after {
				background-image: url("/images/global/arrow-right-purple.svg");
			}
		}
		&:hover {
			color: $white;
			background: $secondary-purple;
			border: 1px $secondary-purple solid;
			.arrow {
				&::after {
					background-image: url("/images/global/arrow-right-white.svg");
				}
			}
		}
	}

	&.transparent {
		color: $white;
		background-color: $transluscent-purple;
		border: none;

		.arrow {
			&::after {
				background-image: url("/images/global/arrow-right-white.svg");
			}
		}
		&:hover {
			color: $secondary-purple;
			background: $white;
			border: none;

			.arrow {
				&::after {
					background-image: url("/images/global/arrow-right-purple.svg");
				}
			}
		}
	}

	&:hover {
		color: $secondary-purple;
		background: $light-purple;
		border: 1px $light-purple solid;
		.arrow {
			&::after {
				background-image: url("/images/global/arrow-right-purple.svg");
			}
		}

		span.plus {
			&::after {
				background-image: url("/images/research/plus-circle-purple.svg");
			}
		}
		span.minus {
			&.light {
				&::after {
					background-image: url("/images/research/minus-circle.svg");
				}
			}
		}
	}
}

.button-row {
	display: flex;
	align-items: center;

	margin: 30px 0;

	> * {
		margin-right: 20px;
	}

	&.center {
		justify-content: center;

		> * {
			margin: 0 10px;
		}
	}

	&.large-spacing {
		margin: 115px 0;
	}
}

a {
	.arrow {
		&::after {
			content: "";
			position: relative;
			display: block;
			background-image: url("/images/global/arrow-right-purple.svg");
			margin-left: rem(16);
			height: rem(24);
			width: rem(24);
			transition: all 0.5s ease-in-out;
		}

		&.down {
			&::after {
				transform: rotate(90deg);
			}
		}

		&.left {
			&::after {
				background-image: url("/images/global/arrow-left-purple.svg");
				margin-left: unset;
				margin-right: rem(16);
			}
		}
	}
}
