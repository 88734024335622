//
// Name:            Navigation
//
// Description:     Contains all navigation styles
//
// ========================================================================
// Component: Navigation
// ========================================================================

.util-topnav,
.topnav {
	@include font(17, auto);
	font-weight: 550;

	display: flex;

	margin-left: 20px;

	vertical-align: top;
	text-transform: capitalize;

	@include tablet-lg {
		margin-left: rem(5);
	}

	a {
		color: $secondary-purple;
	}

	> ul {
		list-style: none;
		padding: 0;

		> li {
			display: inline-block;
			position: relative;
			width: auto;
			margin: 0 12px;
			vertical-align: top;

			> a {
				display: inline-block;
				&:after {
					content: "";
					display: block;
					width: 0;
					left: 0;
					height: 2px;
					background-color: $secondary-purple;
					transition: 0.5s;
				}
				&:hover {
					&:after {
						width: 100%;
					}
				}
			}
			ul.submenu {
				position: absolute;
				top: 30px;
				left: 0;
				z-index: 999;
				width: 300px;
				list-style: none;
				padding: 0;
				border-top: 3px $secondary-purple solid;
				background-color: $offwhite;
				max-height: 0;
				overflow: hidden;
				transform: perspective(400) rotate3d(1, 0, 0, -90deg);
				transform-origin: 50% 0;
				transition: 350ms;
				li {
					width: 100%;
					a {
						display: inline-block;
						width: 100%;
						padding: 10px;
						color: $brand-primary;
						line-height: 1.2;
						&:hover {
						}
					}
				}
			}
			&:hover .submenu {
				max-height: 1000px;
				transform: perspective(400) rotate3d(0, 0, 0, 0);
			}
		}
	}
}

.util-topnav {
	&.no-mbl {
		@include tablet {
			display: none !important;
		}
	}
}

.mbl-util-topnav {
	&.mbl-only {
		@include tablet {
			display: block !important;
		}
	}
}

.ie .topnav > ul > li ul.submenu,
.edge .topnav > ul > li ul.submenu {
	display: none;
}

.ie .topnav > ul > li:hover .submenu,
.edge .topnav > ul > li:hover .submenu {
	display: block;
}

@media only screen and (max-width: 980px) and (min-width: 1px) {
	.header .button {
		display: none;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	.topnav {
		display: block;
		position: absolute;
		top: 70px;
		left: 0;
		width: 100%;
		z-index: 999;
		margin-left: 0;
		background-color: $white;
		font-size: 16px;
		// width: calc(100% + 40px);
		transform: translate3d(100%, 0, 0);
		transition: 0.7s;

		&.open {
			transform: translate3d(0, 0, 0);
		}
		> ul {
			padding: 0 30px;
			> li {
				display: inline-block;
				width: 100%;
				padding: rem(10) 0;
				margin: 0;
				height: auto;
				> a {
					@include font(20, auto);
					color: $secondary-purple;
					width: 100%;
					text-align: center;
					line-height: 30px;
					&:after {
						display: none;
					}
				}
				&:last-child a {
					border-bottom: none;
				}
				ul.submenu {
					top: 0;
					width: 100%;
					position: relative;
					li {
						border-bottom: none;
					}
				}
			}
			.parent a {
			}
		}

		.mbl-util-topnav {
			display: flex;
			background-color: $light-gray;
			flex-direction: column;
			align-items: center;

			ul {
				list-style: none;
				text-align: center;
				padding-left: 0px;
				li {
					padding: rem(6) 0;

					a {
						@include font(20, auto);
						color: $secondary-purple;
						width: 100%;
						text-align: center;
						line-height: 30px;

					}
				}
			}

			#search-form {
				display: flex;
				margin: 0 rem(75);
				align-items: center;
				border-bottom: 1px solid $secondary-purple;
				.search-site {
					background-color: transparent;
					border: none;
					padding-top: rem(5);

					&::placeholder {
						font-family: $campton;
						@include font(20, auto);
						font-weight: 475;
						color: $secondary-purple;
					}
				}
				.search-icon {
					width: rem(16);
					height: rem(16);
				}
			}

			&::after {
				content: "";
				display: block;
				width: 100vw;
				height: rem(300);
				bottom: 0;
				left: 0;
				background-color: $light-gray;
			}
		}
	}
}
