.error-404-page {
    overflow: hidden;
    background-color: #efeff1;

    .wrapper {
        margin: 0 auto;
        padding: rem(130) rem(120);

        @include mobile {
            padding: rem(70) rem(20) rem(20) rem(20);
        }

        .row.flex-row {
            @include tablet {
                flex-direction: column;
                // align-items: center;
            }
        }

        .left-side,
        .right-side {
            flex: 0 1 50%;
            padding: 0 rem(50);
        }

        .text-container {
            display: flex;
            flex-direction: column;

            @include tablet {
                margin-top: rem(50);
            }
        }

        .hyperlink {
            display: flex;
            align-items: center;
        }
    }
}
