.spatiotemporal-scale-card {
    padding: rem(56.5) 0;
    margin: rem(10) 0;
    align-items: center;
    height: rem(550);

    @include tablet {
        height: rem(500);
        padding: rem(20) 0;
    }

    @include mobile {
        padding: rem(0);
        height: unset;
    }

    .text-container {
        margin-right: rem(52);
        flex: 0 1 auto;

        @include tablet {
            margin-right: 0;
        }

        .title.h3 {
            color: $white;
        }

        .button {
            max-width: rem(285);
            a {
                color: $white;
            }

            &:hover {
                a {
                    color: $secondary-purple;
                }
            }
        }
    }

    .image-container {
        position: relative;
        display: flex;
        top: 0;
        right: 0;
        width: rem(524);
        height: rem(524);
        flex: 0 0 auto;

        // transition: opacity 0.2s ease-in-out;

        img {
            width: 100%;
            height: 100%;
            max-width: none;
            object-fit: cover;
            transform: translate(0px, -50%);
        }
    }
}

