.grid {
    position: relative;
    // margin-top: rem(-104);
    display: grid;
    grid-template-areas:
        "item item item"
        "desc desc desc";
    grid-template-columns: 1fr 1fr 1fr;

    // @include tablet {
    //     grid-template-areas:
    //         "item item"
    //         "desc desc";
    //     grid-template-columns: 1fr 1fr;
    //     margin-top: 0;
    // }

    @include mobile {
        grid-template-areas:
            "item"
            "desc";
        grid-template-columns: 1fr;
        margin-top: 0;
    }

    &__item {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: rem(16);
        min-height: rem(500);
        font-size: 5em;
        text-decoration: none;
        z-index: 2;

        @include tablet {
            min-height: rem(400);
        }

        @include mobile {
            min-height: rem(300);
        }

        &#grid-item-1 {
            margin-right: rem(8);

            @include mobile {
                margin-right: unset;
            }
        }

        &#grid-item-2 {
            margin-left: rem(8);
            margin-right: rem(8);

            @include mobile {
                margin-right: unset;
                margin-left: unset;
            }
        }
        &#grid-item-3 {
            margin-left: rem(8);

            @include mobile {
                margin-left: unset;
            }
        }

        &.active {
            .overlay {
                background: linear-gradient(0deg, rgba(51, 21, 95, 0.92) 0%, rgba(51, 21, 95, 0.92) 100%);
                opacity: 1;

            }

            .photo {
                &::after {
                    display: none;
                }
            }

            .expand.button {
                background-color: $white;
                color: $secondary-purple;
            }
        }

        .photo {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: rem(16);

            img {
                width: 100%;
                height: 100%;
                max-width: none;
                object-fit: cover;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            z-index: 2;
        }

        .text-container {
            position: absolute;
            color: $text-light;
            margin: 0 0 rem(28) rem(38);
            z-index: 3;
            bottom: 0;

            @include tablet {
                margin: 0 0 rem(20) rem(20);
            }

            .h3 {
                color: $text-light;
                @include tablet {
                    @include font(24, auto);
                }
            }

            .expand.button {
                min-width: unset;
                max-width: rem(174);
                height: rem(56);
            }
        }
    }

    &__description {
        position: relative;
        top: rem(-120);

        grid-area: desc;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        background: $secondary-purple;
        transition: all 0.4s ease-in-out;
        opacity: 0;
        z-index: 0;

        &.active {
            max-height: 1344px;
            max-width: 1360px;
            transition: all 0.4s ease-in-out;
            border-bottom-left-radius: rem(16);
            border-bottom-right-radius: rem(16);

            opacity: 1;

            @include mobile {
                max-height: unset;
                grid-area: unset;
            }
        }

        div {
            padding: 0 2em;
        }

        .h2,
        .subtitle,
        .super-header,
        .description {
            color: $text-light;
        }

        .subtitle {
            @include font(24, auto);
        }

        .description {
            font-family: $akkurat;
        }

        .super-header {
            @include font(14, auto);
            font-weight: 625;
            letter-spacing: rem(2.24);
            margin-top: rem(70);
            margin-bottom: rem(28);

            &:first-child {
                @include mobile {
                    margin-top: 0;
                }
            }
        }

        .left-side,
        .right-side {
            margin-top: rem(170);
            margin-bottom: rem(70);
        }

        .left-side {
            &.flex-column {
                flex: 0 1 60%;
            }
        }

        .right-side {
            @include mobile {
                margin-top: 0;
            }
            &.flex-column {
                flex: 0 1 40%;
            }
        }

        .collapse.button {
            background-color: $transluscent-purple;
            border: none;
            min-width: unset;
            align-self: flex-end;

            &:hover {
                background-color: $white;
                border: none;
            }
        }

        .news-links {
            list-style: none;
            padding-left: 0px;
            margin-top: 0px;

            li {
                display: flex;
                align-items: flex-end;
                padding: rem(8) 0;
                a {
                    @include font(20, auto);
                    font-weight: 625;
                    color: $text-light !important;
                }

                &:hover {
                    a {
                        text-decoration: underline;
                    }
                }
                .arrow {
                    &::after {
                        content: "";
                        position: relative;
                        display: block;
                        background-image: url("/images/global/arrow-right-white.svg");
                        margin-left: rem(16);
                        height: rem(24);
                        width: rem(24);
                        top: rem(-5);
                    }
                }
            }
        }

        .photo-grid {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin-bottom: rem(60);
        }

        .photo {
            width: 25%; /* Each photo takes 25% width to make 4 photos per row */
            box-sizing: border-box;
            padding: 5px; /* Optional: Add spacing between photos */
        }

        .photo img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: none;
            border-radius: 50%;
            border: 1.6px solid $white;
        }
    }
}

.headshot-grid {
    position: relative;
    // margin-top: rem(-104);
    display: grid;
    grid-template-areas:
        "item item item"
        "desc desc desc";
    grid-template-columns: 1fr 1fr 1fr;

    @include tablet {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "item item"
            "desc desc";
    }

    @include mobile {
        grid-template-areas:
            "item"
            "desc";
        grid-template-columns: 1fr;
        margin-top: 0;
    }

    &__item {
        display: flex;
        flex-direction: column;
        flex: 0 1 33%;

        position: relative;
        align-items: center;

        border: 1px solid #dfdfe2;
        border-radius: rem(8);
        background: linear-gradient(180deg, #efeff1 0%, #fff 100%);
        transition: background 0.25s ease-in-out;
        z-index: 2;


        @include tablet {
            min-height: rem(400);
            flex: 0 1 49%;
        }

        @include mobile {
            min-height: rem(300);
        }

        &#headshot-grid-item-1 {
            margin-right: rem(8);

            @include mobile {
                margin-right: unset;
            }
        }

        &#headshot-grid-item-2 {
            margin-left: rem(8);
            margin-right: rem(8);

            @include mobile {
                margin-right: unset;
                margin-left: unset;
            }
        }
        &#headshot-grid-item-3 {
            margin-left: rem(8);

            @include tablet {
                margin-top: rem(16);
                margin-left: unset;
            }
        }

        &.active {
            background: $primary-purple;
            border: 1px solid $primary-purple;

            .text-container {
                .h4,
                p.title {
                    color: $text-light;
                }
                .expand.button {
                    background-color: $white;
                    color: $secondary-purple;
                }
            }
        }

        .photo {
            position: relative;
            padding: rem(25);
            overflow: hidden;

            img {
                border-radius: rem(8);
                width: 100%;
                height: 100%;
                max-width: none;
                object-fit: cover;
            }
        }

        .text-container {
            position: relative;
            display: flex;

            flex-direction: column;
            justify-content: space-between;
            height: 50%;

            color: $text-light;
            padding: 0 rem(25) rem(28) rem(25);
            z-index: 3;
            bottom: 0;

            @include tablet {
                margin: 0 0 rem(20) rem(20);
            }

            .h4 {
                color: $secondary-purple;
                font-weight: 625;
                @include tablet {
                    @include font(24, auto);
                }
            }

            p.title {
                color: $text-primary;
                font-weight: 475;
            }

            .expand.button {
                min-width: unset;
                max-width: rem(174);
                height: rem(56);
            }
        }
    }

    &__description {
        position: relative;
        top: rem(-120);

        grid-area: desc;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        background: $secondary-purple;
        transition: all 0.4s ease-in-out;
        opacity: 0;
        z-index: 0;

        &.active {
            max-height: 1344px;
            max-width: 1360px;
            transition: all 0.4s ease-in-out;
            border-bottom-left-radius: rem(16);
            border-bottom-right-radius: rem(16);

            opacity: 1;

            @include mobile {
                max-height: unset;
                grid-area: unset;
            }
        }

        div {
            padding: 0 2em;
        }

        .h2,
        .subtitle,
        .super-header,
        .description {
            color: $text-light;
        }

        .subtitle {
            @include font(24, auto);
        }

        .description {
            font-family: $akkurat;
        }

        .super-header {
            @include font(14, auto);
            font-weight: 625;
            letter-spacing: rem(2.24);
            margin-top: rem(70);
            margin-bottom: rem(28);

            &:first-child {
                @include mobile {
                    margin-top: 0;
                }
            }
        }

        .left-side,
        .right-side {
            margin-top: rem(170);
            margin-bottom: rem(70);
        }

        .left-side {
            &.flex-column {
                flex: 0 1 60%;
            }
        }

        .right-side {
            @include mobile {
                margin-top: 0;
            }
            &.flex-column {
                flex: 0 1 40%;
            }
        }

        .news-links {
            list-style: none;
            padding-left: 0px;
            margin-top: 0px;

            li {
                display: flex;
                align-items: flex-end;
                padding: rem(8) 0;
                a {
                    @include font(20, auto);
                    font-weight: 625;
                    color: $text-light !important;
                }

                &:hover {
                    a {
                        text-decoration: underline;
                    }
                }
                .arrow {
                    &::after {
                        content: "";
                        position: relative;
                        display: block;
                        background-image: url("/images/global/arrow-right-white.svg");
                        margin-left: rem(16);
                        height: rem(24);
                        width: rem(24);
                        top: rem(-5);
                    }
                }
            }
        }

        .photo-grid {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
        }

        .photo {
            width: 25%; /* Each photo takes 25% width to make 4 photos per row */
            box-sizing: border-box;
            padding: 5px; /* Optional: Add spacing between photos */
        }

        .photo img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: none;
            border-radius: 50%;
            border: 1.6px solid $white;
        }
    }
}
