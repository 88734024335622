.engage {
    section#engage-hero {
        overflow: hidden;
        padding-top: rem(80);
        .wrapper {
            position: relative;
            // padding: 0 rem(50);
        }

        .row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .text-container {
            h1,
            p {
                flex: 0 1 50%;
            }

            p {
                font-family: $akkurat;
            }
        }

        .ways-to-engage {
            position: relative;
            display: inline-flex;
            flex-direction: column;

            justify-content: center;
            align-items: flex-start;
            border-radius: 24px;

            margin: rem(48) rem(8) rem(48) rem(8);

            padding: rem(48) rem(64);
            background: $primary-purple;
            overflow: hidden;
            gap: 15px;
            z-index: 1;

            &.active {
                margin: rem(48) rem(8) 0 rem(8);
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }

            @include tablet {
                margin: rem(50) rem(30);
            }

            .engage-container {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                align-items: flex-start;
                gap: 24px;
                padding-left: 0px;
                padding-top: rem(24);

                .engage-link {
                    display: flex;
                    align-items: center;
                    padding: 8px 16px;
                    @include font(20, auto);
                    font-weight: 550;
                    border-radius: 3.16px;
                    background: rgba(255, 255, 255, 0.1);
                    color: $text-light;
                    text-decoration: underline;

                    .close-icon {
                        display: inline-flex;
                        color: $primary-purple;
                        margin-left: rem(5);
                        opacity: 0;
                    }

                    &.active {
                        background-color: $white;
                        color: $primary-purple;
                        text-decoration: none;

                        .close-icon {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .engage-details {
            position: relative;
            display: flex;

            max-height: 0;
            max-width: 0;
            // overflow: hidden;
            background: $secondary-purple;
            color: $white;
            transition: all 0.4s ease-in-out;
            opacity: 0;
            z-index: 1;

            .h2,
            .h3,
            .super-header {
                color: $white;
            }

            .h2 {
                font-size: rem(40);
                font-weight: 550;
            }

            p.description {
                @include font(16, auto)
            }


            &.active {
                max-height: 600px;
                max-width: 1360px;
                margin: 0 rem(8) rem(92) rem(8);
                transition: all 0.4s ease-in-out;
                border-bottom-left-radius: rem(16);
                border-bottom-right-radius: rem(16);

                opacity: 1;

                @include tablet-lg {
                    max-height: 1200px;
                }
            }

            .flex-row {
                padding: rem(40);
            }

            .right-side,
            .left-side {
                flex: 0 1 50%;
            }

            .left-side {
                margin-right: rem(20);

            }

            .right-side {
                display: flex;
                align-items: center;
                justify-content: center;

                .image-container {
                    position: relative;
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1 / 1;
                    top: rem(50);

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: none;
                        object-fit: cover;
                        border-radius: rem(16);
                    }
                }
            }
        }
    }

    #learn-with,
    #research-with,
    #work-with {
        .wrapper {
            border-top: 16px solid $secondary-purple;
        }
    }

    .engage-with {
        .wrapper {
            .row {
                display: flex;
                padding-top: rem(71);
                padding-bottom: rem(130);

                @include mobile {
                    flex-direction: column;
                }

                .right-side,
                .left-side {
                    flex: 0 1 50%;

                    @include mobile {
                        flex: 1 1 100%;
                    }

                    .h2,
                    .h3 {
                        color: $secondary-purple;
                    }

                    .h2 {
                        margin-bottom: rem(50);
                    }

                    .h3 {
                        display: flex;
                        align-items: center;

                        .science-icon {
                            margin-right: rem(16);
                        }
                    }
                }
                .right-side {
                    margin-left: rem(50);

                    @include mobile {
                        margin-top: rem(40);
                        padding: 0 rem(20);
                        margin-left: unset;
                    }
                }
                .left-side {
                    margin-right: rem(50);

                    @include mobile {
                        margin-right: unset;
                    }
                }

                .section-title {
                    margin-bottom: rem(50);
                }

                .cta-section {
                    padding: rem(24) 0;
                    border-bottom: 1px solid $light-gray;

                    .button.light {
                        min-width: rem(200);
                    }
                }

                .image-container {
                    position: relative;
                    display: flex;
                    flex: 0 1 auto;
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1 / 1;

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: none;
                        object-fit: cover;
                        border-radius: rem(16);
                    }
                }
            }
        }
    }
}
