.small-form-container {
    margin: rem(50);
    border-radius: rem(16);
    background-color: $secondary-purple;

    @include tablet {
        margin: rem(80);
    }

    @include mobile {
        margin: rem(20);
    }

    .form-inside {
        display: flex;
        padding: rem(64) rem(70);

        @include tablet {
            flex-direction: column;
            padding: 20px; /* Adjust padding for mobile view */
        }
    }

    .h2,
    .super-header,
    .h3 {
        color: $white;
    }

    .left-column {
        flex: 1 0 60%;
        padding: 20px;
        color: $white;

        @include mobile {
            padding: 10px; /* Adjust padding for mobile view */
        }
    }

    .right-column {
        display: flex;
        align-items: center;
        flex: 0 1 40%;
        padding: 20px;
        color: $white;

        @include mobile {
            padding: 10px; /* Adjust padding for mobile view */
        }
    }

    .form {
        display: flex;
        flex-wrap: wrap;
    }

    .form-group {
        flex: 1 1 100%;
        padding: 10px;
    }

    .form-group button {
        margin-top: 0;
        margin-left: rem(50);
        min-width: unset;
        max-width: none;
        width: 100%;
        border: none;
        cursor: pointer;

        @include tablet {
            margin-left: 0;
        }
    }
}
