//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//
// ========================================================================
// Component
// ========================================================================

html {
	font-size: $base-font-size;
	line-height: rem(30);
	font-family: $campton;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

body {
	background: $white;
	color: $text-primary;
	overflow-x: clip;

	// @include mobile {
	// 	overflow-x: hidden;
	// }

	&.fixed {
		overflow: hidden;
	}

	&.menu-open {
		position: fixed;
	}
}

p > a {
	color: $secondary-purple;
	text-decoration: underline;

	&:hover,
	&:focus {
		color: $secondary-purple;
		text-decoration: underline;
	}
}

video::-webkit-media-controls {
	display: none !important;
}

// Screen reader text
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

// Heading styles
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

h1,
.h1 {
	@include font(64, auto, 72);
	font-weight: 625;
	color: $text-secondary;

	@media only screen and (max-width: 1024px) {
		@include font(48, auto, 72);
	}

	@media only screen and (max-width: 568px) {
		@include font(36, auto, 44);
	}
}

h2,
.h2 {
	@include font(48, auto, 64);
	font-weight: 700;
	color: $text-secondary;

	@media only screen and (max-width: 1024px) {
		@include font(48, auto, 72);
	}

	@media only screen and (max-width: 568px) {
		@include font(36, auto, 44);
	}
}

h3,
.h3 {
	@include font(36, auto, 48);
	font-weight: 700;
	color: $text-secondary;

	@media only screen and (max-width: 568px) {
		@include font(24, auto, 32);
	}
}

h4,
.h4 {
	@include font(24, auto, 32);
}

.h1,
.h2,
.h3,
.h4 {
	&.light {
		color: $text-light;
	}
}

// Global text styles
p.subtitle {
	@include font(16, auto, auto);
	font-weight: bold;
	font-weight: bold;
	margin-bottom: 0;
}

p.super-header,
a.super-header {
	@include font(16, auto, auto);
	color: $secondary-purple;
	font-weight: 625;
	text-transform: uppercase;
	letter-spacing: 2.24px;
	word-wrap: break-word;

	&.light {
		color: $white;
	}
}

a.hyperlink {
	@include font(16, auto);
	color: $secondary-purple;
	text-decoration: underline;
	margin-top: rem(36);
}

p.section-body,
p.description {
	font-family: $akkurat;
}

.intro-text {
	@include font(20, auto, 34);
	margin-bottom: 20px;
}

span.bold {
	font-weight: 700;
}
