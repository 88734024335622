//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================
// Component: Variables
// ========================================================================

$base-font-size: 16;
$campton: "Campton Regular", sans-serif;
$akkurat: "Akkurat Pro Regular", sans-serif;

// Brand Colors
$primary-purple: #401f68;
$secondary-purple: #4e2a84;
$light-purple: #f4f0fa;
$transluscent-purple: rgba(255, 255, 255, 0.20);
$primary-gray: #6b7079;
$light-gray: #E4E4E7;

// Text Colors
$text-primary: #15171a;
$text-secondary: $secondary-purple;
$text-light: #ffffff;

// Colors
$brand-primary: #000000;
$background: #f0f0eb;
$offwhite: #fafaf5;
$white: #ffffff;
$black: #000000;
$red: #ed3024;
$teal: #00a499;
$shadow: #fff;

// Forms
$form-field-border-radius: 23px;
$form-field-background: $white;
$form-field-border: 1px solid #ddd;
$form-field-font-size: 17px;
$form-field-color: $shadow;
$form-field-padding: 0 20px;
$form-field-height: 44px;

// Layout
$wrapper-max-width-large: 1440px;
$wrapper-max-width-small: 1200px;
$breakpoints: sm 568px 568px, md 768px 768px, lg 960px 960px, xlg 1200px 1200px !default;
$breakpoint-sm: 568px;
$breakpoint-md: 768px;
$breakpoint-lg: 960px;
$breakpoint-xlg: 1200px;
$breakpoint-max: 1600px;
$flexboxgrid-max-width: $wrapper-max-width-large !default;
