.research {
    section#research-hero {
        overflow: hidden;
        padding: rem(80) 0;
        background-color: $light-gray;
        height: rem(700);

        @include mobile {
            height: auto;
            min-height: rem(464);
        }

        .wrapper {
            position: relative;
            // padding: 0 rem(50);
        }

        .row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .text-container {
            margin: 0 rem(10);
            h1,
            p {
                flex: 0 1 50%;

                @include mobile {
                    flex: 0 1 100%;
                }
            }

            p {
                font-family: $akkurat;
            }

            h1 {
                padding-right: rem(40);
            }
        }
    }

    .application-research {
        position: absolute;
        display: inline-flex;
        flex-direction: column;

        justify-content: center;
        align-items: flex-start;
        border-radius: 24px;

        margin: rem(220) rem(160) rem(160) rem(160);

        padding: rem(79) rem(98) rem(80) rem(98);
        background: $primary-purple;
        overflow: hidden;
        gap: 15px;
        z-index: 1;

        @include tablet {
            margin: rem(220) rem(80);
            padding: rem(48) rem(40);
        }

        @include mobile {
            margin: rem(20) 0;
            position: relative;
        }

        .pillar-link {
            color: $text-light;
            text-decoration: underline;
        }
    }

    .scales {
        position: relative;
        height: auto;
        overflow: auto;
        padding: 0 rem(40);
        top: rem(-150);
        z-index: 0;

        @include mobile {
            display: none;
        }

        .wrapper {
            position: relative;
            background: linear-gradient(0deg, rgba(31, 0, 53, 0.9) 0%, rgba(31, 0, 53, 0.9) 100%),
                url("/images/research/stock-dna.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: rem(16);
        }

        &-header {
            width: 75%;
            padding: rem(195) rem(113);

            @include tablet {
                padding: rem(195) rem(40);
            }

            @include mobile {
                width: 100%;
                padding: rem(40) rem(20);
            }
            .super-header,
            .title,
            .description,
            ul {
                color: $text-light;
            }

            .button {
                max-width: rem(285);
            }
        }

        &-framework {
            display: flex;
            z-index: 1;
            padding: 0 rem(50);

            @include tablet {
                padding: 0;
            }

            @include mobile {
                padding: 0 rem(20);
                // margin-bottom: rem(40);
            }

            .card-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1 0 50%;
                margin-right: rem(36);

                @include tablet-lg {
                    margin-right: rem(25);
                }

                @include mobile {
                    flex: 1 0 100%;
                    margin-right: rem(20);
                }
            }

            .scroll-container {
                display: flex;
                flex-direction: column;
                flex: 0 1 50%;

                &.end {
                    justify-content: flex-end;
                }

                .will-pin {
                    &.pin-spacer {
                        display: flex;
                        order: 0;
                        place-self: auto;
                        grid-area: auto / auto / auto / auto;
                        z-index: auto;
                        float: none;
                        flex-shrink: 1;
                        margin: 0px;
                        inset: 0px;
                        position: relative;
                        flex-basis: auto;
                        overflow: visible;
                        box-sizing: border-box;
                        width: 550px;
                        height: 550.001px;
                        padding: 0px 0px 0.001px;
                    }

                    .image-container {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 550px;
                        height: 550px;

                        @include tablet-lg {
                            width: 450px;
                            height: 450px;
                        }

                        @include tablet {
                            width: 400px;
                            height: 400px;
                        }

                        &.fixed {
                            translate: none;
                            rotate: none;
                            scale: none;
                            top: 135px;
                            margin: 0px;
                            max-width: 550px;
                            width: 550px;
                            max-height: 550px;
                            height: 550px;
                            padding: 0px;
                            box-sizing: border-box;
                            position: fixed;
                            transform: translate(0px, 0px);
                            @include tablet-lg {
                                width: 450px;
                                height: 450px;
                            }
                            @include tablet {
                                width: 400px;
                                height: 400px;
                            }
                        }

                        .background-image {
                            position: absolute;
                            max-width: none;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;

                            @include tablet {
                                margin-right: rem(80);
                            }
                        }

                        .fade-img {
                            position: absolute;
                            max-width: none;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: opacity 0.5s ease-in-out;

                            opacity: 0;

                            @include tablet {
                                margin-right: rem(80);
                            }
                        }
                    }
                }
            }

            .super-header,
            .title,
            .description,
            ul {
                color: $text-light;
            }

            .description,
            ul {
                font-family: $akkurat;
            }

            .icon {
                width: rem(40);
                height: rem(40);
                margin-right: rem(16);
            }
        }
    }

    .scales-mobile {
        display: none;

        @include mobile {
            display: block;

            &.hide {
                display: none;
            }
        }

        .swiper {
            margin: rem(20);

            &-wrapper {
                .swiper-slide {
                    position: relative;
                    background: linear-gradient(0deg, rgba(31, 0, 53, 0.9) 0%, rgba(31, 0, 53, 0.9) 100%),
                        url("/images/research/stock-dna.jpg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: rem(16);

                    .slide-inside {
                        .image-container {
                            max-width: rem(700);
                            height: auto;
                            aspect-ratio: 1 / 1;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                max-width: none;
                            }
                        }

                        .text-container {
                            color: $white;
                            padding: rem(0) rem(50) rem(80) rem(50);

                            .title.h3 {
                                display: flex;
                                align-items: center;
                                .icon {
                                    margin-right: rem(16);
                                }
                            }
                        }
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next {
                color: $white;
                opacity: 0.8;
                top: 44%;
            }
        }
    }
}

.accordion {
    .xl-padding {
        .h2 {
            @include tablet {
                @include font(36, auto);
            }
        }
    }
}
