.events-detail {
    section#event-detail-hero {
        overflow: hidden;
        padding-top: rem(80);
        border-bottom: 16px solid $secondary-purple;

        .wrapper {
            position: relative;
            // padding: 0 rem(50);
        }

        .row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .button {
            min-width: unset;
            border-radius: 8px;
            margin-bottom: rem(24);
        }

        .text-container {
            padding: 0 0 rem(60) 0;
            .left-side {
                margin-right: rem(30);
                flex: 1 0 50%;
            }
            .right-side {
                font-family: $akkurat;
                margin-left: rem(30);
                flex: 0 1 50%;
            }
        }
        
    }

    .event-calendar {
        height: rem(800);
        border-bottom: 16px solid $light-gray;

        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            background-color: $light-gray;
            padding: rem(100);
            border-radius: rem(16);
        }
    }
}
