//
// Name:            Layout
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//
// ========================================================================
// Component: Base
// ========================================================================
.main {
	position: relative;
	padding: 0;
	z-index: 7;
}
.wrapper {
	max-width: $wrapper-max-width-large;
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;

	@include mobile {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.section {
	position: relative;
	vertical-align: top;
}

// Section padding
.section.large-padding {
	padding: rem(50) 0;
}

.section.medium-padding {
	padding: 60px 0;
}

.section.small-padding {
	padding: 40px 0;
}

.xl-padding {
	padding: rem(20) rem(120);

	@include tablet-lg {
		padding: rem(40) rem(40);
	}
}

// Text alignment
.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

// Flex Direction

.flex-column {
	display: flex;
	flex-direction: column;
	flex: 0 1 auto;
}

.flex-row {
	display: flex;
	flex-direction: row;
	flex: 0 1 auto;

	@include mobile {
		flex-direction: column;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.row > [class*="col-"] {
		flex-basis: 100%;
		max-width: 100%;
	}
	.first-xs {
		order: 1;
		-ms-flex-order: 1;
	}
	.last-xs {
		order: 0;
		-ms-flex-order: 0;
	}
}

.mbl-only {
	display: none !important;
	@include mobile {
		display: flex !important;
	}
}

.no-mbl {
	@include mobile {
		display: none !important;
	}
}
