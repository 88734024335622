//
// Name:            Home
//
// Description:     Contains all style definitions concerning basic
//					        structure and layout
//
// ========================================================================
// Component: Home Page
// ========================================================================

.home,
.page-template-template-home {
	section#hero {
		overflow: hidden;
		padding-top: rem(80);

		.row {
			transform: translate(0, 20px);
			opacity: 0;
			transition: 0.3s;
			transition-delay: 0.9s;
			// flex: 0 1 auto;

			div {
				.button-row {
					margin-top: 70px;
				}
			}
		}

		&.loaded {
			.row {
				transform: translate(0);
				opacity: 1;
			}
		}
	}
	.home-content {
		position: relative;
		background-image: url("/images/global/BackgroundElements.svg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding-top: rem(100);
		z-index: -1;

		@include mobile {
			background-image: url("/images/global/BackgroundElements-mobile.svg");
		}
	}
	.section {
		.bg-img {
			position: absolute;
			width: 100vw;
			z-index: -1;
			opacity: 1;
			max-width: unset;
			width: 100%;

			object-fit: cover;

			img {
			}
		}
		.row {
			display: flex;
			flex-wrap: wrap;

			.col-left {
				flex: 1 1 47%;
				max-width: 47%;
				margin-right: 3%;

				@include mobile {
					max-width: 100%;
					margin-right: 0;
					margin-left: 3%;
				}
			}

			.col-right {
				flex: 1 1 47%;
				max-width: 47%;
				margin-left: 3%;

				@include mobile {
					max-width: 100%;
					margin-right: 0;
				}
			}
		}

		pre {
			display: inline-block;
			padding-left: 0.5em;
			padding-right: 0.5em;

			color: $white;
			background-color: rgba($black, 0.5);

			vertical-align: bottom;
		}

		ul > li {
			> a {
				color: $brand-primary;
				text-decoration: underline;

				&:hover,
				&:focus {
					color: $secondary-purple;
					text-decoration: underline;
				}
			}
		}
		.img-container {
			position: relative;
			width: 100%;
			height: auto;
			aspect-ratio: 1 / 1;
			border-radius: 16px;
			flex: 0 1 auto;

			@include mobile {
				height: rem(350);
			}

			.section-img {
				position: absolute;
				max-width: none;
				height: 100%;
				width: 100%;
				object-fit: cover;
				border-radius: 16px;

				@include mobile {
					max-height: rem(300);
				}
			}
		}
		#about-container {
			width: rem(619);

			@include tablet-lg {
				width: rem(476);
			}

			@include mobile {
				width: 100%;
			}
		}

		.small-img-container {
			position: absolute;
			width: 26%;
			height: 33%;
			border: 16px solid $white;
			border-radius: 16px;
			flex: 0 1 auto;
			bottom: 6%;
			left: 23%;

			@include tablet-lg {
				bottom: 30%;
			}

			@include mobile {
				bottom: unset;
				top: 14%;
				left: 42%;
				width: rem(181);
				height: rem(142);
				border: 7px solid white;
			}

			&#small-img-2 {
				left: unset;
				right: 25%;

				@include mobile {
					top: 14%;
					right: unset;
					left: 42%;
				}
			}

			.small-img {
				position: absolute;
				max-width: none;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.text-container {
			flex: 1 1 0;

			@include mobile {
				margin-top: rem(86);
			}
		}

		.section-subtitle {
			color: $secondary-purple;
		}

		.section-body {
			margin-bottom: rem(66);
		}
	}

	#about {
		padding: rem(48) rem(0) rem(0) rem(0);

		@include tablet-lg {
			.section-body {
				margin-bottom: 0;
			}
		}

		.text-container {
			height: rem(464);
			align-items: left;
			justify-content: center;
			margin-left: rem(64);

			@include mobile {
				margin-left: 3%;
				margin-top: 0 !important;
			}
		}

		h2 {
			margin-bottom: 0px;

		}
		.our-three-pillars {
			position: relative;
			bottom: rem(120);
			margin: 0 auto;
			display: inline-flex;
			flex-direction: column;

			justify-content: center;
			align-items: flex-start;

			max-width: 1118px;
			height: auto;
			border-radius: 24px;

			padding: rem(79) rem(98) rem(80) rem(98);
			background: #4e2a84;
			overflow: hidden;
			gap: 15px;

			@include tablet-lg {
				bottom: rem(50);
			}

			@include mobile {
				bottom: 0;
				margin: rem(50) 0 rem(100) 0;
				padding: rem(36) rem(24);
			}
			.pillar-link {
				color: $text-light;
				text-decoration: underline;
			}

		}
	}

	#research {
		padding: rem(0) rem(0) rem(80) rem(0);
	}

	#education {
		// background-image: url('/images/global/bg-img3.svg');
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position:center;

		.small-stat {
			top: 5%;
			left: 32%;

			@include mobile {
				left: unset;
			}
		}
	}

	#entrepreneurship {
		// background-image: url('/images/global/bg-img4.svg');
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position:center;
	}

	.simpleParallax {
		&.simple-parallax-initialized {
			overflow: visible !important;
		}
	}
}
